<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <h2 class="brand-text text-danger ml-1">Job Portal</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div class="alert alert-success p-2" role="alert" v-if="success">
          Verification successful!
        </div>
        <!-- image -->
        <b-img fluid :src="imgUrl" alt="email verify image" />
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BButton, BImg } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
      success: false,
    };
  },
  mounted() {
    this.verifyEmail();
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },

  methods: {
    verifyEmail() {
      axios
        .get("/candidatemailverify/" + this.$route.params.id)
        .then((res) => {
          if (res.data) {
            if (res.data.data.token) {
              this.success = true;
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem("user_name", res.data.data.name);
              localStorage.setItem("email", res.data.data.email);
              localStorage.setItem("phone", res.data.data.phone);
              localStorage.setItem("photo ", res.data.data.photo);
              localStorage.setItem("onboard", 0);
              this.$store.dispatch("setToken", res.data.data);
              this.$router.push({
                name: "onboard",
              });
            }
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            this.e(e.response.data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
